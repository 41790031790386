<template>
  <v-container fluid>
    <v-layout class="pb-6">
      <div style="font-size: 22px; color: black">Danh sách hồ sơ nhân viên</div>
      <v-spacer></v-spacer>
      <!-- <v-btn color="secondaryndary" @click="showCreate">
        <v-icon color="white">mdi-plus</v-icon>
        <span style="color: white" class="btn-add">Thêm Hồ Sơ</span>
      </v-btn> -->
    </v-layout>
    <v-row dense>
      <v-col
        v-for="card in loaiHoSos"
        :key="card.title"
        cols="3"
        class="mb-4 pa-6"
      >
        <v-card elevation="8">
          <v-img
            :src="card.image_url"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="250px"
          >
            <v-card-title v-text="card.name"></v-card-title>
          </v-img>

          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn icon @click="showCreate(card)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn> -->
            <v-btn tile color="success" small @click="showCreate(card)">
              <v-icon left small> mdi-pencil </v-icon>
              Cập nhật
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <create-edit ref="hoso" @on-done="getData"></create-edit>
  </v-container>
</template>

<script>
import CreateEdit from "./create-edit";
import { loaiHoSo } from "@/api/hoso";

export default {
  components: { CreateEdit },
  data: () => ({
    loaiHoSos: [],
  }),
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let data = await loaiHoSo();
      this.loaiHoSos = data;
    },
    showCreate(data) {
      this.$refs.hoso.showFormAdd(data);
    },
  },
};
</script>

<style></style>
