<template>
  <v-container fluid>
    <v-layout align-center>
      <div style="font-size: 20px; color: black" class="mb-4">
        Mức đóng BHXH các tháng
      </div>
      <v-spacer></v-spacer>
      <v-btn color="secondaryndary" dark @click="showFormAdd">
        <v-icon left> mdi-plus </v-icon> Thêm mức đóng
      </v-btn>
    </v-layout>
    <br />
    <v-data-table
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      class="elevation-1"
      loading-text="Đang tải dữ liệu ..."
    >
      <template v-slot:[`item.bat_dau`]="{ item }">
        {{ formatDate(item.bat_dau) }}
      </template>
      <template v-slot:[`item.ket_thuc`]="{ item }">
        {{ formatDate(item.ket_thuc) }}
      </template>
      <template v-slot:[`item.muc_dong`]="{ item }">
        {{ new Intl.NumberFormat({ style: "currency" }).format(item.muc_dong) }}
        VnĐ
      </template>
      <template v-slot:[`item.trang_thai`]="{ item }">
        <v-chip
          v-if="new Date(item.bat_dau) > Date.now()"
          color="primary"
          dark
          small
        >
          Chưa bắt đầu
        </v-chip>
        <v-chip
          v-if="
            new Date(item.bat_dau) <= Date.now() &&
              (new Date(item.ket_thuc) >= Date.now() || !item.ket_thuc)
          "
          color="success"
          dark
          small
        >
          Đang áp dụng
        </v-chip>
        <v-chip
          v-if="new Date(item.ket_thuc) <= Date.now() && item.ket_thuc"
          color="orange"
          dark
          small
        >
          Hết hạn
        </v-chip>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="pink"
              @click="deleteMucDong(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Xóa mức đóng</span>
        </v-tooltip>
        <!-- <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="showFormEdit(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Chi tiết</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="xoaLichNghi(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Xóa mức đóng</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu> -->
      </template>
    </v-data-table>
    <div class="pt-2">
      <v-pagination v-model="page" :length="pageCount" @input="changePage"
        >></v-pagination
      >
    </div>
    <v-dialog v-model="showForm" persistent width="500">
      <v-card>
        <v-card-title class="headline">{{
          edit ? "CẬP NHẬT MỨC ĐÓNG" : "THÊM MỨC ĐÓNG"
        }}</v-card-title>
        <br />
        <v-form ref="form" lazy-validation>
          <v-card-text>
            <div class="label-form">Thời gian bắt đầu áp dụng</div>
            <v-text-field
              placeholder="Ngày bắt đầu áp dụng"
              outlined
              v-model="form.bat_dau"
              type="date"
              dense
              prepend-inner-icon="mdi-calendar"
              :rules="startTimeRules"
            ></v-text-field>
            <div class="label-form">Thời gian hết hạn</div>
            <v-text-field
              placeholder="Nhập mật khẩu mới"
              type="date"
              outlined
              v-model="form.ket_thuc"
              dense
              prepend-inner-icon="mdi-calendar"
            ></v-text-field>
            <div class="label-form">Mức đóng / Tháng</div>
            <v-text-field
              placeholder="VNĐ / Tháng"
              outlined
              v-model="form.muc_dong"
              type="number"
              dense
              prepend-inner-icon="mdi-cash-usd"
              :rules="mucDongRules"
            ></v-text-field>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showForm = false" class="mr-4">Đóng</v-btn>
          <v-btn
            :loading="btnLoading"
            color="primary"
            v-if="edit"
            @click="update"
          >
            <v-icon left>mdi-pencil</v-icon>
            Cập nhật
          </v-btn>
          <v-btn :loading="btnLoading" color="primary" v-else @click="submit">
            <v-icon left>mdi-plus</v-icon>
            Thêm mới
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { addMucDong, getMucDong, editMucDong, xoaMucDong } from "@/api/bhxh";
export default {
  data: () => ({
    edit: false,
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    loading: false,
    btnLoading: false,
    showForm: false,
    form: {
      bat_dau: null,
      ket_thuc: null,
      muc_dong: null,
    },
    tableData: [],
    headers: [
      {
        text: "Ngày bắt đầu áp dụng",
        align: "start",
        sortable: false,
        value: "bat_dau",
      },
      {
        text: "Ngày kết thúc",
        align: "start",
        sortable: false,
        value: "ket_thuc",
      },
      {
        text: "Mức đóng",
        align: "start",
        sortable: false,
        value: "muc_dong",
      },
      {
        text: "Trạng thái",
        align: "start",
        sortable: false,
        value: "trang_thai",
      },
      {
        text: "Hành động",
        value: "action",
        sortable: false,
        align: "center",
        width: "150",
      },
    ],
    startTimeRules: [(v) => !!v || "Thời gian bắt đầu không thể bỏ trống"],
    endTimeRules: [(v) => !!v || "Thời gian kết thúc không thể bỏ trống"],
    mucDongRules: [(v) => !!v || "Mức đóng không hợp lệ"],
  }),
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let data = await getMucDong({
        nhan_vien_id: this.$route.params.id,
        page: this.page,
        perPage: this.itemsPerPage,
      });
      this.tableData = data.data;
      this.pageCount = data.last_page;
    },
    formatDate(e) {
      if (!e) return "Chưa xác định";
      try {
        let date = new Date(e);
        let ngay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let thang =
          Number(date.getMonth() + 1) < 10
            ? "0" + Number(date.getMonth() + 1)
            : Number(date.getMonth() + 1);
        return ngay + "/" + thang + "/" + date.getFullYear();
      } catch (error) {
        return "";
      }
    },
    changePage(val) {
      this.page = val;
      this.getData();
    },
    showFormAdd() {
      this.edit = false;
      this.showForm = true;
      this.resetForm();
    },
    showFormEdit(data) {
      this.edit = true;
      this.showForm = true;
      this.form = { ...data };
    },
    resetForm() {
      this.form = {
        bat_dau: null,
        ket_thuc: null,
        muc_dong: null,
      };
    },
    async submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          await addMucDong({
            ...this.form,
            nhan_vien_id: this.$route.params.id,
          });
          this.show = false;
          this.btnLoading = false;
          this.showForm = false;
          this.resetForm();
          this.getData();
          this.$toast.info("Thêm mức đóng thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
    async deleteMucDong(data) {
      this.$confirmBox.show({
        title: "Xóa mức đóng bảo hiểm xã hội",
        width: 500,
        body:
          "Bạn có chắc chắn muốn xóa mức đóng " +
          "<strong>" +
          new Intl.NumberFormat({ style: "currency" }).format(data.muc_dong) +
          " VnĐ" +
          "</strong><br>" +
          "Thời gian " +
          this.formatDate(data.bat_dau) +
          " - " +
          this.formatDate(data.ket_thuc),
        action: () => xoaMucDong({ id: data.id }),
        onDone: this.getData,
      });
    },
    async update() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          await editMucDong({
            ...this.form,
            nhan_vien_id: this.$route.params.id,
          });
          this.show = false;
          this.btnLoading = false;
          this.showForm = false;
          this.resetForm();
          this.getData();
          this.$toast.info("Thêm mức đóng thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
  },
};
</script>

<style></style>
