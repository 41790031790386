var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-layout',[_c('v-img',{staticClass:"mr-4",attrs:{"max-height":"100","max-width":"100","size":40,"src":_vm.HopDongPic}}),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"headline mb-1"},[_vm._v(" HỢP ĐỒNG NHÂN SỰ")]),_c('v-list-item-subtitle',[_vm._v("Danh sách hợp đồng nhân sự")])],1),_c('v-btn',{attrs:{"color":"secondaryndary"},on:{"click":_vm.addHopDong}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"btn-add",staticStyle:{"color":"white"}},[_vm._v("Thêm hợp đồng")])],1)],1),_c('div',{staticClass:"pt-6 pb-3",staticStyle:{"font-size":"20px","color":"black"}},[_vm._v(" Danh sách hợp đồng ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Đang tải dữ liệu ..."},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.ngay_bat_dau",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.ngay_bat_dau))+" ")]}},{key:"item.ngay_ket_thuc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.ngay_ket_thuc))+" ")]}},{key:"item.ky_duyet",fn:function(ref){
var item = ref.item;
return [(item.ky_duyet)?_c('v-chip',{attrs:{"color":"success","dark":"","small":""}},[_vm._v("Đã ký")]):_c('v-chip',{attrs:{"color":"red","small":"","dark":""}},[_vm._v("Chưa ký")])]}},{key:"item.trang_thai",fn:function(ref){
var item = ref.item;
return [(new Date(item.ngay_bat_dau) > Date.now())?_c('v-chip',{attrs:{"color":"primary","dark":"","small":""}},[_vm._v(" Chưa bắt đầu ")]):_vm._e(),(
          new Date(item.ngay_bat_dau) <= Date.now() &&
            new Date(item.ngay_ket_thuc) >= Date.now()
        )?_c('v-chip',{attrs:{"color":"success","dark":"","small":""}},[_vm._v(" Đang áp dụng ")]):_vm._e(),(
          new Date(item.ngay_ket_thuc) <= Date.now() && item.ngay_ket_thuc
        )?_c('v-chip',{attrs:{"color":"orange","dark":"","small":""}},[_vm._v(" Hết hạn ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.edit(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-pencil ")]),_vm._v(" Chi tiết")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.destroy(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-delete ")]),_vm._v(" Xóa hợp đồng")],1)],1)],1)],1)]}}],null,true)}),_c('div',{staticClass:"pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_vm._v(">")])],1),_c('HopDongForm',{ref:"hopdongform",on:{"fetch-data":_vm.getData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }