import request from "../utils/request";

export function updateSo(data) {
  return request({
    url: "sobhxh",
    method: "post",
    data,
  });
}

export function chiTietSo(params) {
  return request({
    url: "sobhxh",
    method: "get",
    params,
  });
}

export function getMucDong(params) {
  return request({
    url: "mucdongbhxh",
    method: "get",
    params,
  });
}

export function addMucDong(data) {
  return request({
    url: "mucdongbhxh",
    method: "post",
    data,
  });
}

export function editMucDong(data) {
  return request({
    url: "mucdongbhxh",
    method: "put",
    data,
  });
}
export function xoaMucDong(data) {
  return request({
    url: "xoamucdong",
    method: "put",
    data,
  });
}

export function getDanhSachBaoHiem(params) {
  return request({
    url: "danhsachbaohiem",
    method: "get",
    params,
  });
}
export function exportDanhSachBaoHiemYear(params) {
  return request({
    url: "danhsachbaohiem-export-year",
    method: "get",
    params,
    responseType: 'blob',
  });
}

