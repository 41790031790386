<template>
  <DialogCreateEdit
    :width="600"
    :isCreate="isCreate"
    v-model="show"
    title="Thông tin hợp đồng"
    @create="create"
    @update="update"
    :loading="loading"
  >
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="form.loai_hop_dong"
          :items="contract_types"
          :rules="formValidate.required"
          outlined
          dense
          item-text="name"
          item-value="id"
          prepend-inner-icon="mdi-book-open-variant"
          placeholder="Chọn loại hợp đồng"
          hide-details="auto"
        >
        </v-select>
      </v-col>
      <v-col cols="6">
        <DateTimePicker label="Ngày bắt đầu" v-model="form.ngay_bat_dau" />
      </v-col>
      <v-col cols="6">
        <DateTimePicker label="Ngày kết thúc" v-model="form.ngay_ket_thuc" />
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-checkbox
          class="mt-0"
          v-model="form.ky_duyet"
          label="Ký kết"
          color="primary"
          hide-details
        ></v-checkbox>
      </v-col>
    </v-row>
  </DialogCreateEdit>
</template>
<script>
import DialogCreateEdit from "@/components/dialog-create-edit";
import DateTimePicker from "@/components/date-time-picker";
import { getDanhMucCon } from "@/api/danhmuc";
import * as personnelContractApi from "@/api/hopdongnhansu";
export default {
  components: { DialogCreateEdit, DateTimePicker },
  data: () => ({
    contract_types: [],
    show: false,
    isCreate: false,
    form: {
      ngay_bat_dau: new Date().toISOString().substr(0, 10),
      ngay_ket_thuc: new Date().toISOString().substr(0, 10),
    },
    formValidate: {
      required: [(v) => !!v || "Hãy chọn một loại hợp đồng!"],
    },
    loading: false,
  }),
  watch: {
    show(val) {
      if (val) {
        this.getListContractTypes();
      } else {
        this.form = this.$options.data.call(this).form;
      }
    },
  },
  methods: {
    async getListContractTypes() {
      const res = await getDanhMucCon({ code: "HOPDONG" });
      this.contract_types = res;
    },
    async create() {
      try {
        this.loading = true;
        const res = await personnelContractApi.store({
          ...this.form,
          nhan_vien_id: this.$route.params.id,
        });
        this.closeSubmit();
      } catch (err) {
        this.errorSubmit();
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;
        const res = await personnelContractApi.update(this.form, this.form.id);
        this.closeSubmit();
      } catch (err) {
        this.errorSubmit();
      } finally {
        this.loading = false;
      }
    },
    closeSubmit() {
      this.show = false;
      this.$emit("fetch-data");
      this.$toast.info(
        `${this.isCreate ? "Thêm mới" : "Cập nhật"} thành công`,
        {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        }
      );
    },
    errorSubmit() {
      this.$toast.error(`${this.isCreate ? "Thêm mới" : "Cập nhật"} thất bại`, {
        position: "top-center",
        timeout: 6000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    open(isCreate, form = {}) {
      this.isCreate = isCreate;
      if (!isCreate) {
        this.form = { ...this.form, ...form };
      }
      this.show = true;
    },
  },
};
</script>