var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h4',{staticStyle:{"color":"black"}},[_vm._v("Quá trình công tác làm việc")]),(_vm.loading)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"height":"4","indeterminate":"","color":"yellow darken-2"}})],1):_vm._e(),(_vm.data.length == 0 && !_vm.loading)?_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" Không có dữ liệu ")]):_vm._e(),(_vm.data.length > 0 && !_vm.loading)?_c('v-timeline',{attrs:{"dense":_vm.$vuetify.breakpoint.smAndDown}},_vm._l((_vm.data),function(item,index){return _c('v-timeline-item',_vm._b({key:((item.nhan_vien_id) + " - " + index),attrs:{"color":((item.color) + " lighten-2"),"fill-dot":""}},'v-timeline-item',_vm.handleAtt(index),false),[_c('v-card',[_c('v-card-title',{class:((item.color) + " lighten-2")},[_c('v-icon',{staticClass:"mr-4",attrs:{"dark":"","size":"42"}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('h5',{staticClass:"white--text font-weight"},[_vm._v(_vm._s(item.title))]),_c('v-spacer'),_c('div',{staticClass:"white--text"},[_vm._v(" Ngày: "+_vm._s(_vm.formatDate(item.created_at))+" ")])],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"8","md":"7"}},[_c('div',{staticClass:"body-text pb-1"},[_vm._v(" Chức vụ: "),_c('span',{class:[
                    item.type == 'GIANHAPCONGTY' || item.type == 'CHUCVU'
                      ? 'font-weight-bold'
                      : null ]},[_vm._v(_vm._s(item.chuc_vu && item.chuc_vu.name))])]),_c('div',{staticClass:"body-text pb-1"},[_vm._v(" Phòng ban: "),_c('span',{class:[
                    item.type == 'GIANHAPCONGTY' || item.type == 'PHONGBAN'
                      ? 'font-weight-bold'
                      : null ]},[_vm._v(_vm._s(item.phong_ban && item.phong_ban.name))])]),_c('div',{staticClass:"body-text pb-1"},[_vm._v(" Tổ: "),_c('span',{class:[
                    item.type == 'GIANHAPCONGTY' || item.type == 'NHOMTO'
                      ? 'font-weight-bold'
                      : null ]},[_vm._v(_vm._s(item.nhom_to && item.nhom_to.name))])]),_c('div',{staticClass:"body-text pb-2"},[_vm._v(" Hợp đồng: "),_c('span',{class:[
                    item.type == 'GIANHAPCONGTY' || item.type == 'HOPDONG'
                      ? 'font-weight-bold'
                      : null ]},[_vm._v(_vm._s(item.hop_dong && item.hop_dong.name))])])]),_c('v-col',{staticClass:"hidden-sm-and-down text-right",attrs:{"md":"5"}},[_c('v-img',{staticClass:"mr-4",attrs:{"max-height":"200","max-width":"200","src":item.img}})],1)],1)],1)],1)],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }