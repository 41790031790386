<template>
  <v-container fluid>
    <v-row>
      <v-col col="3">
        <v-date-picker
          v-model="picker"
          type="month"
          @change="changeMonth"
        ></v-date-picker>
      </v-col>
      <v-col col="6">
        <div style="font-size: 22px; color: black; margin-bottom: 20px">
          Kết quả công việc trong tháng {{ month }}
        </div>
        <div class="label-form">
          Số ngày làm việc trong tháng: <b>{{ ngayLamViec }} ngày</b>
        </div>
        <div class="label-form">
          Số ngày chấm công: <b>{{ hopLe + khongHople }} ngày</b>
        </div>
        <b class="label-form">Trong đó: </b>
        <div>
          - Chấm công hợp lệ: <b>{{ hopLe }} ngày</b>
        </div>
        <div>
          - Chấm công không hợp lệ: <b>{{ khongHople }} ngày</b>
        </div>
        <div>
          - Thời gian tăng ca:
          <b>{{ tangCa }} phút</b>
        </div>
        <br />
        <div style="font-size: 18px">
          <div>
            <b>Đánh giá xếp loại - Tháng {{ month }}</b>
          </div>
          <br />
          <v-layout>
            <v-select
             v-if="!loadingXepLoai"
              style="width: 70%"
              v-model="danhGia"
              :items="danhGias"
              outlined
              dense
              item-text="name"
              item-value="value"
              prepend-inner-icon="mdi-book-open-variant"
              placeholder="Xếp loại"
              hide-details="auto"
            >
            </v-select>
            <v-layout align-center justify-center v-else>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-layout>

            <v-spacer></v-spacer>
            <v-btn tile color="success" @click="danhGiaNS">
              <v-icon left> mdi-pencil </v-icon>
              Đánh giá
            </v-btn>
          </v-layout>
          <br />
          <v-layout column>
            <div class="pb-3"><b>Ghi chú</b></div>
            <v-textarea
              placeholder="Ghi chú đánh giá"
              rows="2"
              outlined
              dense
              prepend-inner-icon="mdi-note"
              v-model="ghi_chu"
            ></v-textarea>
          </v-layout>
        </div>
      </v-col>
      <v-col col="3"> </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { soNgayChamCong } from "@/api/chamcong";
import { danhGiaNhanVien, getDanhGia } from "@/api/nhanvien";

export default {
  data: () => ({
    picker: new Date().toISOString().substr(0, 10),
    date: [],
    hopLe: 0,
    khongHople: 0,
    tangCa: 0,
    ngayLamViec: 0,
    danhGia: null,
    ghi_chu: "",
    loadingXepLoai: false,
    month: "",
    danhGias: [
      { name: "Loại A", value: "A" },
      { name: "Loại B", value: "B" },
      { name: "Loại C", value: "C" },
      { name: "Loại D", value: "D" },
      { name: "Chưa Xếp Loại", value: null },
    ],
  }),
  mounted() {
    this.changeMonth();
    this.getDanhGiaNS(this.picker);
    this.getNgayCong();
  },
  methods: {
    async getNgayCong() {
      let data = await soNgayChamCong({
        nhan_vien_id: this.$route.params.id,
        date: this.date,
      });
      this.hopLe = data.hop_le;
      this.khongHople = data.khong_hop_le;
      this.tangCa = data.tang_ca;
    },
    async changeMonth() {
      var date = new Date(this.picker),
        y = date.getFullYear(),
        m = +date.getMonth();
      this.month = +m + 1;
      var firstDay = new Date(y, m, 1);
      var lastDay = new Date(y, m + 1, 0);
      this.date = [firstDay, lastDay];
      await this.getNgayCong();
      await this.getDanhGiaNS(date);
      const NgayNghiHangNam = ["04-30", "05-01"];
      this.ngayLamViec = this.getBusinessDatesCount(
        firstDay,
        lastDay,
        [],
        NgayNghiHangNam
      );
    },
    getBusinessDatesCount(startDate, endDate, ngayNghis = [], hangNams = []) {
      const rNgayNghi = ngayNghis.reduce(
        (acc, el) => ({ ...acc, [el]: "" }),
        {}
      );
      const rHangNam = hangNams.reduce((acc, el) => ({ ...acc, [el]: "" }), {});
      var count = 0;
      var curDate = startDate;
      while (curDate <= endDate) {
        var dayOfWeek = curDate.getDay();
        const m = curDate.getMonth() + 1;
        const d = curDate.getDate();
        const key = `${curDate.getFullYear()}-${m < 10 ? "0" + m : m}-${
          d < 10 ? "0" + d : d
        }`;
        const keyM = `${m < 10 ? "0" + m : m}-${d < 10 ? "0" + d : d}`;
        if (
          !(
            dayOfWeek == 6 ||
            dayOfWeek == 0 ||
            rNgayNghi[key] == "" ||
            rHangNam[keyM] == ""
          )
        )
          count++;
        curDate.setDate(curDate.getDate() + 1);
      }
      return count;
    },
    async getDanhGiaNS(time) {
      this.loadingXepLoai = true
      let data = await getDanhGia({
        thoi_gian: time,
        nhan_vien_id: this.$route.params.id,
      });
      this.loadingXepLoai = false
      this.danhGia = data.xep_loai;
      this.ghi_chu = data.ghi_chu;
    },
    async danhGiaNS() {
      try {
        await danhGiaNhanVien({
          thoi_gian: this.picker,
          nhan_vien_id: this.$route.params.id,
          xep_loai: this.danhGia,
          ghi_chu: this.ghi_chu,
        });
        this.$toast.info("Thành công", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.label-form {
  font-size: 17px;
  margin-bottom: 10px;
}
</style>