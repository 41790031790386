<template>
  <v-container fluid>
    <v-layout>
      <v-img
        max-height="100"
        max-width="100"
        :size="40"
        :src="HopDongPic"
        class="mr-4"
      ></v-img>
      <v-list-item-content>
        <v-list-item-title class="headline mb-1">
          HỢP ĐỒNG NHÂN SỰ</v-list-item-title
        >
        <v-list-item-subtitle>Danh sách hợp đồng nhân sự</v-list-item-subtitle>
      </v-list-item-content>
      <v-btn color="secondaryndary" @click="addHopDong">
        <v-icon color="white">mdi-plus</v-icon>
        <span style="color: white" class="btn-add">Thêm hợp đồng</span>
      </v-btn>
    </v-layout>
    <div class="pt-6 pb-3" style="font-size: 20px; color: black">
      Danh sách hợp đồng
    </div>
    <v-data-table
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      class="elevation-1"
      loading-text="Đang tải dữ liệu ..."
    >
      <template v-slot:[`item.ngay_bat_dau`]="{ item }">
        {{ formatDate(item.ngay_bat_dau) }}
      </template>
      <template v-slot:[`item.ngay_ket_thuc`]="{ item }">
        {{ formatDate(item.ngay_ket_thuc) }}
      </template>
      <template v-slot:[`item.ky_duyet`]="{ item }">
        <v-chip v-if="item.ky_duyet" color="success" dark small>Đã ký</v-chip>
        <v-chip v-else color="red" small dark>Chưa ký</v-chip>
      </template>
      <template v-slot:[`item.trang_thai`]="{ item }">
        <v-chip
          v-if="new Date(item.ngay_bat_dau) > Date.now()"
          color="primary"
          dark
          small
        >
          Chưa bắt đầu
        </v-chip>
        <v-chip
          v-if="
            new Date(item.ngay_bat_dau) <= Date.now() &&
              new Date(item.ngay_ket_thuc) >= Date.now()
          "
          color="success"
          dark
          small
        >
          Đang áp dụng
        </v-chip>
        <v-chip
          v-if="
            new Date(item.ngay_ket_thuc) <= Date.now() && item.ngay_ket_thuc
          "
          color="orange"
          dark
          small
        >
          Hết hạn
        </v-chip>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="edit(item.id)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Chi tiết</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="destroy(item.id)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Xóa hợp đồng</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <div class="pt-2">
      <v-pagination v-model="page" :length="pageCount" @input="changePage"
        >></v-pagination
      >
    </div>
    <HopDongForm @fetch-data="getData" ref="hopdongform" />
  </v-container>
</template>

<script>
import HopDongPic from "../../../../../../assets/images/hopdong.svg";
import HopDongForm from "./hopdong-form";
import * as personnelContractApi from "@/api/hopdongnhansu";

export default {
  components: {
    HopDongForm,
  },
  data: () => ({
    loading: false,
    HopDongPic,
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    tableData: [],
    headers: [
      {
        text: "Loại hợp đồng",
        align: "start",
        sortable: false,
        value: "hop_dong.name",
      },
      {
        text: "Ngay bắt đầu",
        align: "start",
        sortable: false,
        value: "ngay_bat_dau",
      },
      {
        text: "Ngày kết thúc",
        align: "start",
        sortable: false,
        value: "ngay_ket_thuc",
      },
      {
        text: "Ký duyệt",
        align: "start",
        sortable: false,
        value: "ky_duyet",
      },
      {
        text: "Trạng thái",
        align: "start",
        sortable: false,
        value: "trang_thai",
      },
      {
        text: "Hành động",
        value: "action",
        sortable: false,
        align: "center",
        width: "150",
      },
    ],
  }),
  mounted() {
    this.getData();
  },
  methods: {
    formatDate(e) {
      if (!e) return null;
      try {
        let date = new Date(e);
        let ngay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let thang =
          Number(date.getMonth() + 1) < 10
            ? "0" + Number(date.getMonth() + 1)
            : Number(date.getMonth() + 1);
        return ngay + "/" + thang + "/" + date.getFullYear();
      } catch (error) {
        return "";
      }
    },
    changePage(val) {
      this.page = val;
      this.getData();
    },
    async getData() {
      try {
        this.loading = true;
        const res = await personnelContractApi.getData({
          page: this.page,
          nhan_vien_id: this.$route.params.id,
        });
        this.tableData = res.data.data;
        this.page = res.data.current_page;
        this.pageCount = res.data.last_page;
      } finally {
        this.loading = false;
      }
    },
    addHopDong() {
      this.$refs.hopdongform.open(true);
    },
    async destroy(id) {
      this.$confirmBox.show({
        title: "Xoá hợp đồng",
        width: 500,
        body: "Bạn có chắc chắn muốn xóa hợp đồng này",
        action: () => personnelContractApi.destroy(id),
        onDone: () => this.getData(),
      });
    },
    notify(isSuccess = true, mess) {
      let params = {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      };
      if (isSuccess) {
        this.$toast.info(`${mess} thành công`, {
          timeout: 2000,
          ...params,
        });
      } else {
        this.$toast.error(`${mess} thất bại`, {
          timeout: 6000,
          ...params,
        });
      }
    },
    async edit(id) {
      const res = await personnelContractApi.show(id);
      if (res) {
        this.$refs.hopdongform.open(false, res.data);
      }
    },
  },
};
</script>

<style></style>
