var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticStyle:{"font-size":"16px","color":"black"}},[_vm._v("Số năm công tác: "),_c('b',[_vm._v(_vm._s(_vm.dataInfoWorkDay.year_of_seniority))]),_vm._v(" (năm)")]),_c('div',{staticStyle:{"font-size":"15px","font-style":"italic"}},[_vm._v(_vm._s(_vm.dataInfoWorkDay.description))]),_c('div',{staticClass:"mt-4 mb-2",staticStyle:{"font-size":"17px","font-weight":"bold","color":"black"}},[_vm._v("Thông tin nghỉ phép")]),_c('div',{staticClass:"pb-2"},[_vm._v("Số ngày nghỉ phép: "),_c('b',[_vm._v(_vm._s(_vm.dataInfoWorkDay.number_of_days_off)+" ngày")])]),_c('div',{staticClass:"pb-2",staticStyle:{"font-size":"16px"}},[_vm._v(" Số ngày đã nghỉ: "),_c('b',[_vm._v(_vm._s(_vm.dataInfoWorkDay.num_was_day_off)+" ngày")])]),_c('div',{staticClass:"pb-2",staticStyle:{"font-size":"16px"}},[_vm._v(" Số ngày phép còn lại: "),_c('b',[_vm._v(_vm._s(_vm.handleMinus(_vm.dataInfoWorkDay.number_of_days_off, _vm.dataInfoWorkDay.num_was_day_off))+" ngày")])]),_c('div',{staticClass:"title pt-2 pb-2"},[_vm._v("Danh sách đăng ký nghỉ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Đang tải dữ liệu ..."},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"indigo","size":"28"}},[(item.nguoi_gui.url_image)?_c('img',{attrs:{"src":_vm.imageEndpoint + item.nguoi_gui.url_image,"alt":"ManhLe"}}):(item && item.nguoi_gui.name)?_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(item.nguoi_gui.name.charAt(0).toUpperCase()))]):_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-account")])],1),_c('span',{staticClass:"ml-3",staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.nguoi_gui.name))])]}},{key:"item.thoi_gian_nghi",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.bat_dau))+" - "+_vm._s(_vm.formatDate(item.ket_thuc))+" ")]}},{key:"item.trang_thai",fn:function(ref){
var item = ref.item;
return [(
        _vm.TRANG_THAI.CHO_DUYET == item.trang_thai &&
        new Date(item.ket_thuc) >= Date.now()
      )?_c('v-chip',{attrs:{"color":"primary","dark":"","small":""}},[_vm._v(" Chờ duyệt ")]):_vm._e(),(_vm.TRANG_THAI.TU_CHOI == item.trang_thai)?_c('v-chip',{attrs:{"color":"pink","dark":"","small":""}},[_vm._v(" Từ chối ")]):_vm._e(),(_vm.TRANG_THAI.DA_DUYET == item.trang_thai)?_c('v-chip',{attrs:{"color":"success","dark":"","small":""}},[_vm._v(" Được phê duyệt ")]):_vm._e(),(
        _vm.TRANG_THAI.CHO_DUYET == item.trang_thai &&
        new Date(item.ket_thuc) <= Date.now()
      )?_c('v-chip',{attrs:{"color":"orange","dark":"","small":""}},[_vm._v(" Hết hạn ")]):_vm._e()]}},{key:"item.nguoi_duyet",fn:function(ref){
      var item = ref.item;
return [(item.nguoi_duyet)?_c('div',[_c('v-avatar',{attrs:{"color":"indigo","size":"28"}},[(item.nguoi_duyet && item.nguoi_duyet.url_image)?_c('img',{attrs:{"src":_vm.imageEndpoint + item.nguoi_duyet.url_image,"alt":"ManhLe"}}):(item.nguoi_duyet && item.nguoi_duyet.name)?_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(item.nguoi_duyet.name.charAt(0).toUpperCase()))]):_vm._e()]),(item.nguoi_duyet)?_c('span',{staticClass:"ml-3",staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.nguoi_duyet.name))]):_vm._e()],1):_vm._e()]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-pencil ")]),_vm._v(" Chi tiết")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.xoaLichNghi(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-delete ")]),_vm._v(" Xóa lịch nghỉ")],1)],1)],1)],1)]}}],null,true)}),_c('div',{staticClass:"pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_vm._v(">")])],1),_c('create-edit',{ref:"thaisan",on:{"on-done":_vm.getData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }