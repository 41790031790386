<template>
  <v-container fluid>
    <h4 style="color: black">Quá trình công tác làm việc</h4>
    <div class="d-flex justify-center" v-if="loading">
      <v-progress-circular height="4" indeterminate color="yellow darken-2"></v-progress-circular>
    </div>
    <div class="d-flex justify-center" v-if="data.length == 0 && !loading">
      Không có dữ liệu
    </div>
    <v-timeline :dense="$vuetify.breakpoint.smAndDown" v-if="data.length > 0 && !loading">
      <v-timeline-item
        v-for="(item, index) in data"
        :key="`${item.nhan_vien_id} - ${index}`"
        :color="`${item.color} lighten-2`"
        fill-dot
        v-bind="handleAtt(index)"
      >
        <v-card>
          <v-card-title :class="`${item.color} lighten-2`">
            <v-icon dark size="42" class="mr-4"> {{ item.icon }} </v-icon>
            <h5 class="white--text font-weight">{{ item.title }}</h5>
            <v-spacer></v-spacer>
            <div class="white--text">
              Ngày: {{ formatDate(item.created_at) }}
            </div>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col cols="8" md="7">
                <div class="body-text pb-1">
                  Chức vụ:
                  <span
                    :class="[
                      item.type == 'GIANHAPCONGTY' || item.type == 'CHUCVU'
                        ? 'font-weight-bold'
                        : null,
                    ]"
                    >{{ item.chuc_vu && item.chuc_vu.name }}</span
                  >
                </div>
                <div class="body-text pb-1">
                  Phòng ban:
                  <span
                    :class="[
                      item.type == 'GIANHAPCONGTY' || item.type == 'PHONGBAN'
                        ? 'font-weight-bold'
                        : null,
                    ]"
                    >{{ item.phong_ban && item.phong_ban.name }}</span
                  >
                </div>
                <div class="body-text pb-1">
                  Tổ:
                  <span
                    :class="[
                      item.type == 'GIANHAPCONGTY' || item.type == 'NHOMTO'
                        ? 'font-weight-bold'
                        : null,
                    ]"
                    >{{ item.nhom_to && item.nhom_to.name }}</span
                  >
                </div>
                <div class="body-text pb-2">
                  Hợp đồng:
                  <span
                    :class="[
                      item.type == 'GIANHAPCONGTY' || item.type == 'HOPDONG'
                        ? 'font-weight-bold'
                        : null,
                    ]"
                    >{{ item.hop_dong && item.hop_dong.name }}</span
                  >
                </div>
              </v-col>
              <v-col class="hidden-sm-and-down text-right" md="5">
                <v-img
                  max-height="200"
                  max-width="200"
                  :src="item.img"
                  class="mr-4"
                ></v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>

<script>
import giaNhappic from "../../../../../../assets/images/congtac/gianhap.svg";
import hopDongPic from "../../../../../../assets/images/congtac/hopdong.svg";
import chucVuPic from "../../../../../../assets/images/congtac/chucvu.svg";
import khuVucPic from "../../../../../../assets/images/congtac/khuvuc.svg";
import { getList } from "@/api/congtac";
export default {
  data: () => ({
    giaNhappic,
    hopDongPic,
    chucVuPic,
    khuVucPic,
    data: [],
    loading: false,
  }),
  created() {
    this.fetchData();
  },
  methods: {
    formatDate(e) {
      if (!e) return null;
      try {
        let date = new Date(e);
        let ngay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let thang =
          Number(date.getMonth() + 1) < 10
            ? "0" + Number(date.getMonth() + 1)
            : Number(date.getMonth() + 1);
        return ngay + "/" + thang + "/" + date.getFullYear();
      } catch (error) {
        return "";
      }
    },
    async fetchData() {
      try {
        this.loading = true;
        const res = await getList({ nhan_vien_id: this.$route.params.id });
        this.data = res.data.map((x) => {
          return {
            ...x,
            ...this.showByType(x.type),
          };
        });
      } finally {
        this.loading = false;
      }
    },
    showByType(type) {
      let result = {
        title: "",
        color: "",
        img: "",
        icon: "",
      };
      if (type == "PHONGBAN") {
        result.title = "Thay đổi phòng ban";
        result.color = "red";
        result.img = this.khuVucPic;
        result.icon = "mdi-map";
      } else if (type == "CHUCVU") {
        result.title = "Thay đổi chức vụ";
        result.color = "cyan";
        result.img = this.chucVuPic;
        result.icon = "mdi-account-convert";
      } else if (type == "NHOMTO") {
        result.title = "Thay đổi nhóm tổ";
        result.color = "red";
        result.img = this.khuVucPic;
        result.icon = "mdi-map";
      } else if (type == "GIANHAPCONGTY") {
        result.title = "Gia Nhập công ty";
        result.color = "purple";
        result.img = this.giaNhappic;
        result.icon = "mdi-account-check";
      } else if (type == "HOPDONG") {
        result.title = "Thay đổi hợp đồng";
        result.color = "amber";
        result.img = this.hopDongPic;
        result.icon = "mdi-book-open-page-variant";
      }
      return result;
    },

    handleAtt(index) {
      if (index % 2 == 0) {
        return {
          right: true,
        };
      } else {
        return {
          left: true,
          small: true,
        };
      }
    },
  },
};
</script>

<style scoped>
.body-text {
  font-size: 16px;
  color: black;
}
</style>