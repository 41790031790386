<template>
  <v-dialog v-model="show" persistent width="600">
    <v-card>
      <v-card-title class="headline"> {{ name }} </v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <!-- <v-col cols="12" class="zero-vertical">
              <div>{{name}}</div>
            </v-col> -->
            <v-col cols="12" class="zero-vertical">
              <div class="label-form">Ghi chú</div>
              <v-textarea
                v-model="form.mo_ta"
                placeholder="Ghi chú"
                outlined
                dense
                prepend-inner-icon="mdi-grease-pencil"
                rows="3"
              ></v-textarea>
            </v-col>
            <input
              name="file"
              ref="upload-image"
              style="display: none"
              type="file"
              multiple
              @change="handleUpload($event)"
            />
            <v-col cols="12" class="zero-vertical">
              <div class="label-form">Tệp đính kèm</div>
              <div class="file-container">
                <v-tooltip
                  bottom
                  v-if="tienTrinhUpload == 0 || tienTrinhUpload == 100"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      style="background: #b9f6ca"
                      class="box-file align-center justify-center mr-6 ml-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="clickUpload"
                    >
                      <v-icon large color="green darken-2"> mdi-plus </v-icon>
                    </div>
                  </template>
                  <span>Thêm tập tin</span>
                </v-tooltip>
                <v-progress-circular
                  v-else
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="tienTrinhUpload"
                  color="secondary"
                >
                  {{ tienTrinhUpload }}
                </v-progress-circular>

                <div
                  class="box-file mr-6 mb-4 ml-2"
                  v-for="(item, index) in form.files"
                  :key="index"
                >
                  <div class="d-flex justify-end">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          medium
                          color="pink"
                          v-bind="attrs"
                          v-on="on"
                          @click="xoaFile(item)"
                        >
                          mdi-close</v-icon
                        >
                      </template>
                      <span>Xóa tập tin</span>
                    </v-tooltip>
                  </div>
                  <div class="box-file align-center justify-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          @click="taiTapTin(item)"
                          large
                          color="green darken-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-download</v-icon
                        >
                      </template>
                      <span>Tải xuống</span>
                    </v-tooltip>

                    <div>Tập tin {{ index + 1 }}</div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-8 pb-3">
        <v-spacer></v-spacer>
        <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
        <v-btn :loading="btnLoading" color="primary" @click="capNhatThamSo">
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { chiTietHoSo, uploadFile, capNhaHoSo, xoaTapTin } from "@/api/hoso";

export default {
  data: () => ({
    btnLoading: false,
    name: "",
    show: false,
    tienTrinhUpload: 0,
    endpoint: process.env.VUE_APP_BASE,
    form: {
      mo_ta: null,
      nhan_vien_id: null,
      files: [],
    },
    numberRules: [
      (v) => !!v || "Giá trị không hợp lệ",
      (v) => (v && v >= 0) || "Giá trị phải lơn hơn 0",
    ],
  }),
  mounted() {},
  methods: {
    async getChiTiet(loaiHoSoId) {
      let data = await chiTietHoSo({
        nhan_vien_id: this.$route.params.id,
        loai_ho_so_id: loaiHoSoId,
      });
      if (data) {
        this.form.mo_ta = data.mo_ta;
        this.form.files = data.files;
        this.form.nhan_vien_id = this.$route.params.id;
      } else {
        this.form.mo_ta = "";
        this.form.files = [];
        this.form.nhan_vien_id = this.$route.params.id;
      }
      console.log(this.form);
    },
    showFormAdd(data) {
      this.name = data.name;
      this.show = true;
      this.form.loai_ho_so_id = data.id;
      this.form.mo_ta = "";
      this.form.files = [];
      this.getChiTiet(data.id);
    },
    clickUpload() {
      this.$refs["upload-image"].click();
    },
    async handleUpload(e) {
      var isValidate = true;
      let files = e.target.files;
      let data = new FormData();
      if (!files || files.length == 0) {
        return;
      }
      for (let el of files) {
        const isLt2M = el.size / 1024 / 1024 < 20;
        if (!isLt2M) {
          this.$toast.warning("Kích thước file ảnh tối đa 20Mb!", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
          isValidate = false;
          this.files = [];
          break;
        }
        data.append("file[]", el);
      }

      if (!isValidate) return;

      try {
        let File_id = await uploadFile(data, (e) => {
          this.tienTrinhUpload = e;
          console.log(this.tienTrinhUpload);
        });
        this.form.files.push(...File_id);
      } catch (error) {
        console.log(error);
      }
      this.$refs["upload-image"].value = null;
    },
    xoaFile(data) {
      this.$confirmBox.show({
        title: "Xóa file đính kèm",
        width: 480,
        body: "Bạn có chắc chắn muốn xóa file này ra khỏi hồ sơ",
        action: () => {
          this.xoaTapTin(data.id);
        },
        onDone: () => {
          this.getChiTiet(this.form.loai_ho_so_id);
        },
      });
    },
    async xoaTapTin(id) {
      await xoaTapTin({ id: id });
    },
    taiTapTin(data) {
      window.open(this.endpoint + data.link, "_blank");
    },

    async capNhatThamSo() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          await capNhaHoSo(this.form);
          this.show = false;
          this.btnLoading = false;
          this.$emit("on-done");
          this.$toast.info("Cập nhật thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
  },
};
</script>
<style scoped>
.zero-vertical {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.box-file {
  height: 100px;
  width: 100px;
  display: flex;
  flex-direction: column;
  background: #cfd8dc;
}
.file-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
