import request from "../utils/request";

export function loaiHoSo() {
  return request({
    url: "loaihoso",
    method: "get",
  });
}

export function chiTietHoSo(params) {
  return request({
    url: "hoso",
    method: "get",
    params
  });
}

export function capNhaHoSo(data) {
  return request({
    url: "hoso",
    method: "post",
    data
  });
}

export function uploadFile(data, progress = undefined) {
  return request({
    url: "uploadhoso",
    method: "post",
    onUploadProgress: (progressEvent) => {
      if (progress !== undefined) {
        if (progressEvent.lengthComputable) {
          progress(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        } else {
          progress(progressEvent.loaded);
        }
      }
    },
    data,
  });
}

export function xoaTapTin(data) {
  return request({
    url: "xoataptinhoso",
    method: "put",
    data
  });
}