<template>
  <v-container fluid>
    <v-row>
      <v-col offset="1" :cols="6" class="pl-8">
        <div class="title black--text mb-8">Thông tin về sổ BHXH nhân viên</div>
        <v-layout class="tieu-de pb-4" align-center
          >Số thẻ BHYT:
          <b class="pl-3" v-if="!editSothe">{{ soBHXH.ma_the_bhyt }}</b>
          <v-text-field
            class="ml-4"
            v-else
            placeholder="Nhập số sổ"
            outlined
            dense
            prepend-inner-icon="mdi-barcode"
            hide-details
            v-model="soBHXH.ma_the_bhyt"
          ></v-text-field>

          <v-spacer></v-spacer>
          <v-btn icon color="green darken-4" @click="editSothe = !editSothe">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-layout>

        <v-layout class="tieu-de pb-4" align-center
          >Số sổ: <b class="pl-3" v-if="!editSoSo">{{ soBHXH.so_so }}</b>
          <v-text-field
            class="ml-4"
            v-else
            placeholder="Nhập số sổ"
            outlined
            dense
            prepend-inner-icon="mdi-barcode"
            hide-details
            v-model="soBHXH.so_so"
          ></v-text-field>

          <v-spacer></v-spacer>
          <v-btn icon color="green darken-4" @click="editSoSo = !editSoSo">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-layout>
        <v-layout class="tieu-de pb-4" align-center
          >Ngày cấp:
          <div class="pl-3" v-if="!editNgayCap">{{ soBHXH.ngay_cap }}</div>
          <v-text-field
            class="ml-4"
            v-else
            placeholder="Nhập ngày cấp"
            outlined
            dense
            prepend-inner-icon="mdi-calendar"
            hide-details
            type="date"
            v-model="soBHXH.ngay_cap"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="green darken-4"
            @click="editNgayCap = !editNgayCap"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-layout>
        <v-layout class="tieu-de pb-4" align-center
          >Số cấp lần:
          <div class="pl-3" v-if="!editLanCap">{{ soBHXH.lan_cap }}</div>
          <v-text-field
            class="ml-4"
            v-else
            placeholder="Sổ cấp lần thứ ?"
            outlined
            dense
            prepend-inner-icon="mdi-black-mesa"
            hide-details
            v-model="soBHXH.lan_cap"
            type="number"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn icon color="green darken-4" @click="editLanCap = !editLanCap">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-layout>
        <v-layout class="tieu-de pb-4" align-center
          >Ngày nhận sổ BHXH:
          <b class="pl-3" v-if="!editNgayNhan">{{ soBHXH.ngay_nhan }}</b>
          <v-text-field
            class="ml-4"
            v-else
            placeholder="Nhập nhận sổ"
            outlined
            dense
            prepend-inner-icon="mdi-calendar"
            hide-details
            type="date"
            v-model="soBHXH.ngay_nhan"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="green darken-4"
            @click="editNgayNhan = !editNgayNhan"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-layout>
        <v-layout class="tieu-de pt-6 pb-8" align-center>
          <b>Ghi chú:</b>
          <i class="pl-3" v-if="!editGhiChu">{{ soBHXH.ghi_chu }}</i>
          <v-textarea
            v-else
            placeholder="Ghi chú "
            class="ml-4"
            rows="2"
            outlined
            dense
            prepend-inner-icon="mdi-note"
            hide-details
            v-model="soBHXH.ghi_chu"
          ></v-textarea>

          <v-spacer></v-spacer>
          <v-btn icon color="green darken-4" @click="editGhiChu = !editGhiChu">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-layout>
        <div class="pt-8">
          <v-btn tile color="success" @click="update">
            <v-icon left> mdi-pencil </v-icon>
            Cập nhật
          </v-btn>
        </div>
      </v-col>
      <v-col :cols="4" style="text-align: center">
        <img
          height="400"
          :src="soBHXH.image_url ? imageEndpoint + soBHXH.image_url : imageBHXH"
        />
        <br />
        <v-btn color="secondary" dark class="mt-2" @click="uploadAvatar">
          Upload
          <v-icon right dark> mdi-cloud-upload </v-icon>
        </v-btn>
        <input
          ref="upload-image"
          name="avatar"
          style="display: none"
          type="file"
          @change="handleUpload($event)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { updateSo, chiTietSo } from "@/api/bhxh";
import { uploadAvatar } from "@/api/user";
import imageBHXH from "../../../../../../assets/images/soBHXH.jpg";
export default {
  data: () => ({
    imageBHXH,
    imageEndpoint: process.env.VUE_APP_BASE,

    soBHXH: {},
    data: {},
    editSoSo: false,
    editNgayCap: false,
    editLanCap: false,
    editNgayNhan: false,
    editGhiChu: false,
    editSothe: false,
  }),
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let data = await chiTietSo({ nhan_vien_id: this.$route.params.id });
      if (data.data) {
        this.soBHXH = data.data;
        this.data = { ...this.soBHXH };
      }
    },
    resetForm() {
      this.editSoSo = false;
      this.editNgayCap = false;
      this.editLanCap = false;
      this.editNgayNhan = false;
      this.editGhiChu = false;
    },
    async update() {
      try {
        if (!this.editSoSo) {
          this.soBHXH.so_so = this.data.so_so;
        }
        if (!this.editNgayCap) {
          this.soBHXH.ngay_cap = this.data.ngay_cap;
        }
        if (!this.editLanCap) {
          this.soBHXH.lan_cap = this.data.lan_cap;
        }
        if (!this.editNgayNhan) {
          this.soBHXH.ngay_nhan = this.data.ngay_nhan;
        }
        if (!this.editGhiChu) {
          this.soBHXH.ghi_chu = this.data.ghi_chu;
        }
        await updateSo({ ...this.soBHXH, nhan_vien_id: this.$route.params.id });
        this.$toast.info("Cập nhật thành công", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        this.resetForm();
      } catch (error) {
        console.log(error);
      }
    },
    uploadAvatar() {
      this.$refs["upload-image"].click();
    },
    handleUpload(e) {
      let files = e.target.files;
      let data = new FormData();
      data.append("file", files[0]);
      var filePath = files[0].name.split(".").pop(); //lấy định dạng file
      var dinhDangChoPhep = ["jpg", "jpeg", "png"]; //các tập tin cho phép
      const isLt2M = files[0].size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.form.fileList.pop();
        this.$toast.warning("Kích thước file ảnh tối đa 20Mb!", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        return false;
      }
      if (!dinhDangChoPhep.find((el) => el == filePath.toLowerCase())) {
        this.loadingUpload = false;
        this.listLoading = false;
        this.iconUpload = "el-icon-bottom";
        this.$toast.warning("Tập tin không hợp lệ!", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        return;
      }
      uploadAvatar(data)
        .then((res) => {
          this.soBHXH.image_url = res;
        })
        .catch((error) => {});

      this.$refs["upload-image"].value = null;
    },
  },
};
</script>

<style scoped>
.tieu-de {
  font-size: 18px;
  color: black;
}
</style>
