var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"600"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('br'),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{staticClass:"zero-vertical",attrs:{"cols":"12"}},[_c('div',{staticClass:"label-form"},[_vm._v("Ghi chú")]),_c('v-textarea',{attrs:{"placeholder":"Ghi chú","outlined":"","dense":"","prepend-inner-icon":"mdi-grease-pencil","rows":"3"},model:{value:(_vm.form.mo_ta),callback:function ($$v) {_vm.$set(_vm.form, "mo_ta", $$v)},expression:"form.mo_ta"}})],1),_c('input',{ref:"upload-image",staticStyle:{"display":"none"},attrs:{"name":"file","type":"file","multiple":""},on:{"change":function($event){return _vm.handleUpload($event)}}}),_c('v-col',{staticClass:"zero-vertical",attrs:{"cols":"12"}},[_c('div',{staticClass:"label-form"},[_vm._v("Tệp đính kèm")]),_c('div',{staticClass:"file-container"},[(_vm.tienTrinhUpload == 0 || _vm.tienTrinhUpload == 100)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"box-file align-center justify-center mr-6 ml-2",staticStyle:{"background":"#b9f6ca"},on:{"click":_vm.clickUpload}},'div',attrs,false),on),[_c('v-icon',{attrs:{"large":"","color":"green darken-2"}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,3315543737)},[_c('span',[_vm._v("Thêm tập tin")])]):_c('v-progress-circular',{attrs:{"rotate":360,"size":100,"width":15,"value":_vm.tienTrinhUpload,"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.tienTrinhUpload)+" ")]),_vm._l((_vm.form.files),function(item,index){return _c('div',{key:index,staticClass:"box-file mr-6 mb-4 ml-2"},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"medium":"","color":"pink"},on:{"click":function($event){return _vm.xoaFile(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close")])]}}],null,true)},[_c('span',[_vm._v("Xóa tập tin")])])],1),_c('div',{staticClass:"box-file align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"large":"","color":"green darken-2"},on:{"click":function($event){return _vm.taiTapTin(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-download")])]}}],null,true)},[_c('span',[_vm._v("Tải xuống")])]),_c('div',[_vm._v("Tập tin "+_vm._s(index + 1))])],1)])})],2)])],1)],1)],1),_c('v-card-actions',{staticClass:"pr-8 pb-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v("Đóng")]),_c('v-btn',{attrs:{"loading":_vm.btnLoading,"color":"primary"},on:{"click":_vm.capNhatThamSo}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Cập nhật ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }