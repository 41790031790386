import request from "../utils/request";

export function getData(params ={}) {
  return request({
    url: "persionnel-contract",
    method: "get",
    params,
  });
}

export function show(id,params = {}) {
  return request({
    url: `persionnel-contract/${id}`,
    method: "get",
    params,
  });
}

export function update(data = {}, id) {
  return request({
    url: `persionnel-contract/${id}`,
    method: "post",
    data,
  });
}

export function store(data) {
  return request({
    url: "persionnel-contract",
    method: "post",
    data,
  });
}

export function destroy(id) {
  return request({
    url: `persionnel-contract/${id}`,
    method: "delete",
  });
}
